<template>
  <div class="creatorSubscriptionCard third-font" v-if="isVisible">
    <div class="title">
      <span strong>{{ $locale["become_premium"] }}</span>
      <div class="titleOption">
        <a class="messageButton" @click="goToMessages">
          <iconic name="comment-o" />
          <span>{{ $locale["message"] }}</span>
        </a>
      </div>
    </div>
    <div class="option">
      <a class="primary-button subscribeButton" @click="goToSubscribe">
        <span>{{ $locale["subscribe"] }}</span>
        <span v-html="getSubscriptionPrice()"></span>
      </a>
    </div>
  </div>
</template>

<script>
import CreatorPostSubscribe from "./CreatorPostSubscribe.vue";
export default {
  mixins: [CreatorPostSubscribe],
  computed: {
    isVisible: function() {
      const isGrid = this.$route.query.feed_mode !== "plus" && this.$route.query.feed_mode !== "grid";
      return isGrid;
    },
    creatorId: function() {
      return this.$store.state.posts.profile._id;
    },
  },
  methods: {
    goToMessages: function() {
      if (!this.$user) {
        this.requestUserLogin({ post: this.post });
        return;
      }

      this.setQuery({ messages: this.creatorId });
    },
    goToSubscribe: function() {
      this.BuySubscription(this.$store.state.posts.profile);
    },
    getSubscriptionPrice: function() {
      const creator = this.$store.state.posts.profile;

      if (!creator.subscriptions) {
        return 0;
      }

      let price = creator.subscriptions.priceByMonth.mount;

      price = this.number_format(price, 2);

      return `<span class="number">${price}</span> <span class="currency">USD</span> / <span class="period">${this.$locale["month"]}</span>`;
    },
  },
};
</script>

<style lang="scss">
.creatorSubscriptionCard {
  padding: $mpadding;
  .title {
    font-size: 1.2rem;
    margin-bottom: $mpadding;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .titleOption .messageButton {
    display: inline-flex;
    align-items: center;
    padding: $mpadding/2 0;
    border-radius: 2pc;
    cursor: pointer;
    gap: $mpadding/2;

    &:active {
      @include active_button;
    }
  }

  .currency,
  .period {
    font-size: 90%;
  }
  .number {
    font-family: Ubuntu Bold;
  }
}
</style>
